import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const VolkhovenWeiler: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.VolkhovenWeiler,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Volkhoven/Weiler"
          points="254,272 308,268 340,248 360,290 380,304 366,318 366,348 356,334 324,348 319.7,343.3"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 312.6464 291.2102)"
        >
          <tspan x="0" y="0">
            Volk-
          </tspan>
          <tspan x="0" y="14.4">
            hoven/
          </tspan>
          <tspan x="0" y="28.8">
            Weiler
          </tspan>
        </text>
      </g>
    </>
  );
});
